import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

interface ACDatePickerProps {
  value: Dayjs | null;
  onDateChange: (date: string) => void;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

export const ACDatePicker = ({ onDateChange, error, setError, value }: ACDatePickerProps) => {
  const [open, setOpen] = useState(false);

  const dayOfWeekFormatter = (day: string) => {
    const firstLetter = day.charAt(0).toUpperCase();
    const secondLetter = day.charAt(1).toLowerCase();
    return `${firstLetter}${secondLetter}`;
  };

  const shouldDisableDate = (day: Dayjs) => {
    return day.isAfter(dayjs().add(65, 'day'));
  };

  const handleDateChange = (newValue: Dayjs | null) => {
    const formattedDate = newValue ? newValue.format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');

    if (newValue && newValue.isBefore(dayjs(), 'day')) {
      setError('Policy start date is invalid.');
    } else if (newValue && newValue.isAfter(dayjs().add(65, 'day'), 'day')) {
      setError('Policy start date is invalid.');
    } else {
      setError('');
    }

    onDateChange(formattedDate);
  };

  return (
    <>
      <DatePicker
        value={value}
        onChange={handleDateChange}
        shouldDisableDate={shouldDisableDate}
        disablePast={true}
        open={open}
        onClose={() => setOpen(false)}
        format="MMMM DD, YYYY"
        dayOfWeekFormatter={dayOfWeekFormatter}
        slotProps={{
          textField: {
            onClick: () => setOpen(true),
            fullWidth: true,
          },
        }}
        sx={{
          backgroundColor: '#f7f9fc',
          borderColor: '#d9d9d9',
          '& .MuiOutlinedInput-root': {
            padding: '0 15px 0 5px !important',
            '& fieldset': {
              borderColor: '#d9d9d9',
            },
            '&:hover fieldset': {
              borderColor: '#d9d9d9',
            },
            '&.Mui-focused fieldset': {
              border: '1px solid #6366F1',
            },
          },
        }}
      />

      {error && <div className="text-[#d02b2b] font-normal mt-1">{error}</div>}
    </>
  );
};
